import Vue from "vue";
import Router from "vue-router";
// import axios from "axios";

Vue.use(Router);


const router = new Router({
        mode: "history",
        base: process.env.BASE_URL,
        routes: [
            {
                path: "/",
                component: () => import("@/layouts/default/Index.vue"),
                children: [
                    {
                        path: "/",
                        name: "MainPage",
                        component: () => import("@/components/MainPage.vue"),
                        meta: {
                            title: "Main",
                            appBarFullWidth: false
                        },
                        //beforeEnter: requireAuth()
                    },

                    {
                        path: "/Products",
                        name: "ProductPage",
                        component: () => import("@/components/ProductPage.vue"),
                        meta: {
                            title: "Product",
                            appBarFullWidth: false
                        },
                        //beforeEnter: requireAuth()
                    },

                    {
                        path: "/Contact",
                        name: "ContactPage",
                        component: () => import("@/components/ContactPage.vue"),
                        meta: {
                            title: "Contact",
                            appBarFullWidth: false
                        },
                        //beforeEnter: requireAuth()
                    },

                    {
                        path: "/ProductDetail",
                        name: "ProductDetail",
                        component: () => import("@/components/ProductDeatil.vue"),
                        meta: {
                            title: "ProductDetail",
                            appBarFullWidth: false
                        },
                        //beforeEnter: requireAuth()
                    },

                    {
                        path: "/Plan",
                        name: "Plan",
                        component: () => import("@/components/PlanPage.vue"),
                        meta: {
                            title: "Plan",
                            appBarFullWidth: false
                        },
                        //beforeEnter: requireAuth()
                    },


                ],
            },


        ],
        scrollBehavior(to, from, savedPosition) {
            // always scroll to top
            return {top: 0}
        }
        ,
    })
;
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0)
// })

export default router;
