<template>
  <router-view/>
</template>

<script>
//import '@/styles/index.css'
//import HelloWorld from './components/HelloWorld';


export default {
  name: 'App',

  components: {
    //HelloWorld,
  },

  data: () => ({
    //
  }),
};
</script>
